export default {
  "language": "CZ",
  "m_campaign": "Kampaň",
  "m_home": "Domů",
  "m_compare": "Srovnej",
  "m_challenges": "Challenges",
  "m_event": "Event",
  "m_events": "Events",
  "m_charts": "Grafy",
  "m_options": "Možnosti",
  "m_about": "O nás",
  "m_vChanges": "Změny {version}",
  "m_vNewPhotos": "Nové Fotky {version}",
  "m_library": "Knihovna",
  "m_addLibrary": "Přidat do knihovny",
  "m_sharableLink": "Sdílej link",
  "m_copy": "Zkopíruj",
  "m_listCars": "Seznam aut",
  "m_type": "Typ",
  "m_search": "Hledej",
  "m_searchCar": "Hledej auto",
  "m_searchTracks": "Hledej trať",
  "m_selectTrack": "Vyber trať",
  "m_contributors": "Přispěvatelé",
  "m_printBy": "vytvořeno",
  "m_colors": "Barvy",
  "m_more3dot": "Více...",
  "m_trackset": "Trackset",
  "m_clear": "Vymazat",
  "m_clearCars": "Vymazat auta",
  "m_load": "Načíst",
  "m_loadChart": "Načíst graf",
  "m_memory": "Paměť",
  "m_image": "Obrázek",
  "m_pleaseWait3dot": "Prosím čekejte...",
  "m_downloadPng": "Stáhnout PNG",
  "m_save": "Uložit",
  "m_cancel": "Zrušit",
  "m_login": "Přihlásit",
  "m_gotoLogin": "Přihlašte se",
  "m_email": "Email",
  "m_emailVerification": "Potvrzení emailem",
  "m_username": "Uživatelské jméno",
  "m_password": "Heslo",
  "m_newPassword": "Nové heslo",
  "m_repeatPassword": "Zopakovat heslo",
  "m_repeat": "Zopakovat",
  "m_charLeast": "{n} znaků min.",
  "m_register": "Registrovat",
  "m_forgot": "Zapomenout",
  "m_allmostDone": "Skoro hotovo",
  "m_here": "Zde",
  "m_filter": "Filtr | Filtry",
  "m_change": "Změnit",
  "m_analyze": "Analyzovat",
  "m_submitReview": "Nahrát ke schválení",
  "m_saveToLibrary": "Uložit do knihovny",
  "m_delete": "Smazat",
  "m_approve": "Schválit",
  "m_round": "Kolo | Kola",
  "m_requirements": "Požadavky",
  "m_empty": "Prázdné",
  "m_emptyRound": "Prázdné kolo",
  "m_lockRound": "Zamkni pro mě toto kolo",
  "m_unlockRound": "Odemkni toto kolo",
  "m_findBest": "Najdi nejlepší",
  "m_other": "Jiné",
  "m_win": "výhra",
  "m_lose": "prohra",
  "m_draw": "remíza",
  "m_notime": "žádný čas",
  "m_select": "vybrat",
  "m_resetSolutions": "Resetuj pouze řešení",
  "m_resetRound": "Resetuj kolo",
  "m_includeDownvote": "Zahrnout časy s downvote?",
  "m_showDataFromPast": "Zobraz data ze starších verzí",
  "m_showOldTags": "Zobraz vyřazené tagy",
  "m_challengeName": "Název challenge",
  "m_eventName": "Název eventu",
  "m_numberRounds": "Počet kol",
  "m_yes": "Ano",
  "m_no": "Ne",
  "m_new": "Nový",
  "m_by": "od",
  "m_checkOut": "Podívej se",
  "m_templateGuide": "Pokyny pro šablony",
  "m_createNewCg": "Vytvoř novou challenge",
  "m_createNewEvent": "Vytvoř nový event",
  "m_title": "Nadpis",
  "m_twisty": "Zatáčky",
  "m_drag": "Drag",
  "m_city": "Město",
  "m_rqLimit": "RQ limit",
  "m_points": "Body",
  "m_time": "Čas",
  "m_timeToBeat": "Čas k překonání",
  "m_uploadPrint": "Nahrát print",
  "m_done": "Hotovo",
  "m_sort": "Třídit",
  "m_send": "Poslat",
  "m_backHome": "Zpět domů",
  "m_any": "Jakékoliv",
  "m_rarity": "Rarita",
  "m_car": "Auto | Auta",
  "m_changed": "Změněno",
  "m_noRecords": "Bez záznamu",
  "m_showOtherTracks": "Ukaž jiné tratě",
  "m_showMore": "Ukaž více",
  "m_lastContribution": "Poslední příspěvky",
  "m_nothingFound": "Nic nenalezeno",
  "m_changedCars": "změněné auta",
  "m_uploadSuccess": "Upload úspěšný",
  "m_noData": "Žádné data k zobrazení",
  "m_noTimesFound": "Žádné časy nenalezeny",
  "m_logoutSuccess": "Odhlášení úspěšné",
  "m_loginSuccess": "Jste přihlášeni",
  "m_deleteSuccess": "Úspěšně smazáno",
  "m_saveSuccess": "Úspěšně uloženo",
  "m_approveSuccess": "Úspěšné schváleno",
  "m_sentReview": "Nahráno pro posouzení",
  "m_language": "Jazyk",
  "m_logout": "Odhlásit",
  "m_resetSavedHand": "Resetuj na původní řešení",
  "m_showCustomTunes": "Ukaž custom tunes",
  "m_kingForceVertical": "Změnit zobrazení na vertikální",
  "m_showBigCards": "Ukaž velké karty",
  "m_chartHideOutOfFilter": "Skryj vozy z filtru",
  "m_meta": "Meta",
  "m_race": "Závod",
  "m_useTrackList": "Použij tracklist",
  "m_modShowSuspectTimes": "Skryj podezřelé časy",
  "m_newByCopy": "Nový zkopírováním",
  "m_eventShowOnlyPicks": "Ukaž pouze výběry",
  "m_eventForcePicks": "Ukaž vybraná auta",
  "m_showPointsCgForce": "ukaž body",
  "m_statsView": "Pohled na statistiky",
  "m_multi": "Multi",
  "m_multiTags": "Více tagů",
  "m_hidden": "Skrytý",
  "m_enablePicks": "Umožni výběr",
  "m_clearPicks": "Vyčisti výběr",
  "m_findRqSavers": "Najdi RQ savers",
  "m_homePointsToggle": "???",
  "m_zoom": "Zoom",
  "m_review": "Schválení | Schválení ",
  "m_addReview": "Přidej schválení",
  "m_clubs": "Clubs",
  "m_showContributors": "Ukaž přispěvatele",
  "m_tracksetName": "Název tracksetu",
  "m_tracksets": "Tracksets",
  "m_reqs": "Požadavky",
  "m_reqsName": "Název požadavků",
  "m_daySelector": "Výběr dne",
  "m_current": "aktuální",
  "m_saveDay": "Ulož den",
  "m_createNewTrackset": "Vytvoř nový trackset",
  "m_createNewReqs": "Vytvoř nové požadavky",
  "m_saveTrackset": "Ulož trackset",
  "m_saveReqs": "Ulož požadavky",
  "m_saveOriginalOrder": "Ulož v originálním pořadí",
  "m_showOriginalOrder": "Ukaž originální pořadí",
  "m_filterPage": "Filtr stránky",
  "m_notCurrent": "Neaktuální",
  "m_admin": "Admin",
  "m_carFinder": "Hledač aut",
  "m_newPhotos": "Nové fotky",
  "m_testPoints": "Testovací body",
  "m_downTimes": "Downvoted časy",
  "m_translationTool": "Překlady",
  "m_newCar": "Nové auta",
  "m_relativeBest": "Relativně k nejlepšímu",
  "m_2xmultiplier": "2x Násobič",
  "m_cheatSheet": "Cheatsheet",
  "m_simulateUntilGetAll": "Simuluj dokud nedostaneš vše",
  "m_simulateUntilGetOne": "Simuluj dokud nedostaneš jedno",
  "m_simulate": "Simuluj",
  "m_simulating": "Simuluji",
  "m_impossibleRemoved": "{count} nevyhovujících aut vyřazeno",
  "m_count": "Počet",
  "m_quantityOfCars": "Počet aut",
  "m_success": "Úspěch",
  "m_failed": "Neúspěch/Selhání",
  "m_status": "Status",
  "m_cost": "Cena",
  "m_stop": "Stop",
  "m_probabilityPerOpen": "Pravděpodobnost při otevření",
  "m_cumulativeProbability": "Celková pravděpodobnost",
  "m_impossiblePack": "Tento balíček není možný",
  "m_superLucky": "Super Lucky",
  "m_veryLucky": "Very Lucky",
  "m_goodLuck": "Good Luck",
  "m_littleLucky": "Little Lucky",
  "m_badLuck": "Bad Luck",
  "m_worstLuck": "Worst Luck",
  "m_zeroLuck": "Zero Luck",
  "m_impossible": "Nemožné",
  "m_customizePack": "Uprav balíček",
  "m_impossibleCondition": "Tyto podmínky nejsou možné",
  "m_permanents": "Stálé",
  "m_linkDiscord": "Připoj svůj discord účet",
  "m_sixDigitCode": "6místný kód",
  "m_discordUserId": "Uživatelské Discord ID",
  "m_discordLinkFinish": "Tvůj TDR a Discord účet jsou propojeny!",
  "m_submitYourCommunity": "Nahraj moji komunitu",
  "m_wantToJoin": "Chceš se přidat?",
  "m_private": "Soukromé",
  "m_public": "Veřejné",
  "m_official": "Oficiální",
  "m_platform": "Platforma",
  "m_selectPlatform": "Vyber platformu",
  "m_selectCountry": "Vyber zemi",
  "m_userCount": "Uživatelský účet",
  "m_description": "Popis",
  "m_optional": "Možné",
  "m_informationForInvite": "Informace pro pozvání",
  "m_link": "Link",
  "m_unlinkDiscord": "Odpoj můj Discord",
  "m_inviter": "Pozvánka",
  "m_requestSent": "Žádost poslána",
  "m_edit": "Upravit",
  "m_saveToFile": "Uložit do souboru",
  "m_loadFile": "Načíst",
  "m_replace": "Nahradit",
  "m_eventPack": "Balíček k eventu",
  "m_accelCurveMaker": "Generátor křivky zrychlení",
  "m_speed": "Rychlost",
  "m_clearBoard": "Vyčisti plochu",
  "m_longTerm": "Dlouhodobé",
  "m_selectType": "Vyber typ",
  "m_rewardedCar": "Auta za odměnu",
  "m_deleteEvent": "Smaž event",
  "m_paste": "Vložit",
  "m_date": "Datum",
  "m_dateEnd": "Konec data",
  "m_images": "Obrázky",
  "m_links": "Odkazy",
  "m_exportToTimeline": "Exportuj do Timeline",
  "m_spec": "Specifikace",
  "m_text": "Text",
  "m_noGoal": "Bez cíle",
  "m_specificCar": "Konkrétní auto",
  "m_specificAttr": "Konkrétní atribut",
  "m_nextPage": "Další strana",
  "m_previousPage": "Předchozí strana",
  "m_videoShowcase": "Animované karty",
  "m_video": "Video",
  "m_addVideo": "Přidat video",
  "m_removeVideo": "Odstranit video",
  "m_toggleVideo": "Zapnout video",
  "m_downloadImage": "Uložit obrázek",
  "m_removeCar": "Odstranit auto",
  "m_needHelp": "Potřebujete pomoc?",
  "m_instructions": "Instrukce",
  "m_myGarage": "Moje garáž",
  "m_topDrivesWrapped": "Top Drives Wrapped",
  "m_wrapped": "Wrapped",
  "m_carCount": "Auta",
  "m_fullyUpgraded": "Plný",
  "m_prizes": "Prizes",
  "m_nonPrizes": "Non-Prizes",
  "m_nonPrizesMissing": "Chybějící prizes",
  "m_mostWins": "Nejvíc výher",
  "m_mostLoses": "Nejvíc proher",
  "m_mostUsed": "Nejvíc použito",
  "m_highWinRate": "Nejvyšší win rate",
  "m_highLoseRate": "Nejvyšší lose rate",
  "m_oldestNoUse": "Nejstarší nepoužitý",
  "m_mostUseDay": "Nejvíc používaný/den",
  "m_lessUseDay": "Nejméně používaný/den",
  "m_oldest": "Nejstarší",
  "m_mostWinDay": "Nejvíc výher/den",
  "m_mostLoseDay": "Nejvíc proher/den",
  "m_newGroup": "Nová skupina",
  "m_newTimeline": "Nová timeline",
  "m_uploadMyGarage": "Nahraj moji garáž",
  "m_updateGarage": "Updatuj garáž",
  "m_higherRQ": "Nejvyšší RQ",
  "m_showPrizeBoard": "Ukaž prize board",
  "m_solutionsInPacks": "Řešení v Balíčcích",
  "p_userSentCgForAnalyse": "Díky! Vaše kolo bude zkontrolováno. Pokud si o tom chceš promluvit, připoj se na Discord.",
  "p_emptyRoundForUser": "Toto kolo ještě není hotové. Můžeš pomoci tím, že ho nahraješ pro schválení.",
  "p_emptyRoundVoteForUser": "Je to v pořádku?",
  "p_emptyRound2": "Toto kolo ještě není hotové. Pokud jsi v tomto kole, můžeš pomoc tím, že ho nahraješ.",
  "p_emptyRoundLogin": "Přihlaš se, aby si mohl vytvořit toto kolo nebo kontaktuj moderátora na našem discord serveru.",
  "p_lockRoundPhrase": "Zamkni si toto kolo aby si na něm mohl začít pracovat.",
  "p_modDoingRound": "{mod} pracuje na tomto kole.",
  "p_patronsOnly": "Tato funkce je k dispozici pouze pro patrony (Tier{tier})",
  "p_bestOfDescription": "Ukáže vám seznam nejlepších aut pro zvolenou trať a filtr. Příklad:",
  "p_chartsDescription": "Vezme celou databázi do grafu. Pro ukázku:",
  "p_templateWarning": "Ujistěte se, že vaše šablona bude užitečná pro komunitu, jinak nebude schválena.",
  "p_about1": "Tento projekt vytvořil TiagoXavi a nemá jakoukoliv spojitost s Hutch Games Ltd. Veškeré použité assets jsou majetkem Hutch Games Ltd.",
  "p_about2": "Jakýkoliv problém nebo návrh? Prosím, kontaktujte nás na Discordu nebo pošlete email na mighty.boy@topdrivesrecords.com. ",
  "p_about3": "TDR je bez reklam a zdarma pro každého. Můžete podpořit projekt aby zůstal online a byl dále vylepšován.",
  "p_resetPassHelper": "Napište váš mail pro obdržení odkazu na reset hesla.",
  "p_resetPassIfExist": "Uživateli s tímto emailem byl zaslán link pro resetování hesla.",
  "p_confirmationWillSent": "Potvrzující email bude zaslán",
  "p_checkYourEmailBox": "Prosím, zkontrolujte si vaši emailovou schránku",
  "p_spamToo": "I váš spambox!",
  "p_somethingWrong": "Něco se pokazilo",
  "p_beforeLeave": "Jste si jistý, že chcete odejít?",
  "p_eventsKingDescription": "Můžeš vidět pouze 1 auto z každé rarity. Tady se podívej, jak kompletní seznam funguje:",
  "p_eventsKingLogin": "Přihlaš se aby si viděl nejlepší auto pro každou raritu.",
  "p_eventHiddenForPatreons": "Tento event je preview dostupný pouze pro patrony Tier 3",
  "p_linkDiscordExplanationCommu": "K nahrání vaší soukromé komunity potřebujete:",
  "p_linkDiscordExplanationP1": "K propojení vašeho účtu potřebujete:",
  "p_linkDiscordExplanationP2": "• A Discord účet• Připoj se k TDR Discord serveru • Získej Discord Uživatelské ID (použij příkaz /user on TDR Discord server)",
  "p_linkDiscordCodeSent": "Kód byl poslán jako soukromá zpráva na Discord.",
  "p_informationForInvite": "Jaké informace musí lidé z vaší komunity zadat pro připojení (email, telefoní číslo, uživatelské jméno,...) Toto bude zobrazeno uživatelům, kteří se chtějí přidat do vaší komunity.",
  "p_inviteYourDiscord": "Obdržíte žádosti o připojení na váš discord, hlídejte si to.",
  "p_privateDescript": "Lidé budou žádat o vstup (Potřebujete účet na Discordu)",
  "p_publicDescript": "Zde je veřejný link a každý se může pridat.",
  "p_userSentCommunityForAnalyse": "Děkujeme! Vaše komunita bude analyzována moderátorským týmem TDR.",
  "p_communityToBeInvited": "Pro pozvání odpovězte na otázky níže.",
  "p_cgNotThisTime1": "Challenge zde není dostupná.",
  "p_cgNotThisTime2": "Rozhodli jsme se nedávat tuto challenge na TDR. Pojďme hrát jako za starých časů, jen tak pro zábavu. Než vzniklo TDR, dělali jsme to tak všichhni, takže to zvládneme i teď. Možná to bude moc těžké nebo riskantní, ale jde jen o tuto challenge. Všechny ostatní zde budou jako obvykle.",
  "p_cgNotThisTime3": "Proč? Přišli jsme o některé sociální vazby při hledání pomoci a řešení. Tentokrát tedy chceme vidět, jak si hráči pomáhají navzájem, místo toho, aby na TDR našli všechna řešení. Pomáhat si navzájem je základem každé komunity a je jich tu opravdu mnoho - Discord, Facebook, Reddit, Youtube. Nejsi v žádné komunitě? V záložkách najdeš odkazy na spoustu z nich, tak si najdi takovou, která se ti bude líbit:",
  "p_cgNotThisTime4": "Jen aby bylo jasno. Toto není paywall. Ani patreoni a moderátoři se nemohou na challenge podívat.",
  "p_cgNotThisTime5": "Doufám, že toto pochopíte. Bavte se!",
  "p_privateGarageDesc": "Uvidíš pouze ty",
  "p_publicGarageDesc": "Uvidí lidé s odkazem",
  "g_joinDiscord": "Přidej se na TDR Discord server a napiš nám o vylepšení tvých šablon nebo proč tvoje šablona byla zamítnuta.",
  "g_rule0": "Tvoje šablona musí dávat smysl. Například stejný tag, stejná zemně, stejné pneumatiky, stejná značka, stejná rarita... Případně jiný kontext - k aktuálním eventům / challengím.",
  "m_display": "Ukázat",
  "g_rule1": "Vlož všchny auta v daném kontextu. Nepřidávej jen ty auta, které máš.",
  "g_rule2": "Šablony by měli srovnávat auta s podobnými statistikami. Nemá smysl srovnávat RWD Perf a 4WD All-surface na suchém asfaltu.",
  "g_rule3": "Kombinuj on road a off road tratě pouze pokud je k tomu důvod.",
  "g_rule4": "Není třeba přidávat všechny tratě. Mohlo by to způsobit nestabilitu aplikace.",
  "g_rule5": "Snaž se srovnat tratě podle jejich typu a povrchu.",
  "g_rule6": "Snaž se srovnat auta podle RQ (první nejvyšší) nebo podle výkonu na tratích.",
  "g_rule7": "Šablona nemusí obsahovat všechny časy, ty chybějící můžeš doplnit.",
  "g_rule8": "Použij popisný název.",
  "g_rule9": "Zkontroluj, zda stejná šablona již neexistuje.",
  "s_dry": null,
  "s_aspht": null,
  "s_wet": null,
  "s_dirt": null,
  "s_gravel": null,
  "s_ice": null,
  "s_sand": null,
  "s_snow": null,
  "s_grass": null,
  "c_topSpeed": null,
  "c_handling": null,
  "c_hand": null,
  "c_class": null,
  "c_year": null,
  "c_tyre": null,
  "c_drive": null,
  "c_clearance": null,
  "c_country": null,
  "c_brand": null,
  "c_tag": null,
  "c_stock": null,
  "c_weight": null,
  "c_fuel": null,
  "c_seats": null,
  "c_enginePos": null,
  "c_brakeClass": null,
  "c_brake": null,
  "c_bodyStyle": null,
  "c_prizeCar": null,
  "c_prize cars": null,
  "c_non-prize cars": null,
  "c_mid": null,
  "c_low": null,
  "c_high": null,
  "c_performance": null,
  "c_standard": null,
  "c_all-surface": null,
  "c_off-road": null,
  "c_slick": null,
  "c_saloon": null,
  "c_roadster": null,
  "c_coupe": null,
  "c_hatchback": null,
  "c_suv": null,
  "c_convertible": null,
  "c_estate": null,
  "c_pickup": null,
  "c_mpv": null,
  "c_van": null,
  "c_dragster": null,
  "c_twister": null,
  "c_speedster": null,
  "c_all-rounder": null,
  "c_petrol": null,
  "c_diesel": null,
  "c_electric": null,
  "c_hybrid": null,
  "c_bioethanol": null,
  "c_hydrogen": null,
  "c_misc": null,
  "c_frontEngine": null,
  "c_midEngine": null,
  "c_mid-rearEngine": null,
  "c_mixedEngine": null,
  "c_rearEngine": null,
  "c_tune": null,
  "c_name": null,
  "c_template": null,
  "c_tcs": null,
  "c_abs": null,
  "t_drag100b": null,
  "t_drag100": null,
  "t_drag120": null,
  "t_drag150b": null,
  "t_drag150": null,
  "t_drag170": null,
  "t_drag200": null,
  "t_drag60b": null,
  "t_drag60": null,
  "t_mile1": null,
  "t_mile1r": null,
  "t_mile2": null,
  "t_mile4": null,
  "t_mile4r": null,
  "t_drag100150": null,
  "t_drag30130": null,
  "t_drag50150": null,
  "t_drag75125": null,
  "t_airplaneHangars": null,
  "t_airplaneSlalom": null,
  "t_smallRoad": null,
  "t_butte": null,
  "t_canyonButte": null,
  "t_lookout": null,
  "t_canyonLookout": null,
  "t_dtRoad": null,
  "t_canyonDtRoad": null,
  "t_canyonTour": null,
  "t_carPark": null,
  "t_townSlalom": null,
  "t_townSprint": null,
  "t_csMed": null,
  "t_csSmall": null,
  "t_townTour": null,
  "t_cross": null,
  "t_fast": null,
  "t_fastr": null,
  "t_fieldSlalom": null,
  "t_figureEight": null,
  "t_forestRiver": null,
  "t_forest": null,
  "t_forestSlalom": null,
  "t_frozenLake": null,
  "t_frozenRivSla": null,
  "t_gForce": null,
  "t_gForcer": null,
  "t_hairpin": null,
  "t_hClimb": null,
  "t_hClimbr": null,
  "t_indoorKart": null,
  "t_kart": null,
  "t_draglshape": null,
  "t_mnGforce": null,
  "t_mnHairpin": null,
  "t_mnCityNarrow": null,
  "t_mnCity": null,
  "t_mnCityLong": null,
  "t_moto": null,
  "t_mtHairpin": null,
  "t_mtHill": null,
  "t_mtIncline": null,
  "t_mtSlalom": null,
  "t_mtTour": null,
  "t_mtTwisty": null,
  "t_northloop": null,
  "t_northloop1": null,
  "t_northloop2": null,
  "t_northloop3": null,
  "t_northloop4": null,
  "t_northloop5": null,
  "t_oceanSlalom": null,
  "t_oceanCity": null,
  "t_oceanHighway": null,
  "t_oceanLongDrag": null,
  "t_oceanParking": null,
  "t_oceanShortDrag": null,
  "t_rallyMed": null,
  "t_rallySmall": null,
  "t_riverSprint": null,
  "t_runwayDrag": null,
  "t_runwayDragSlalom": null,
  "t_runwayLoop": null,
  "t_serviceRoads": null,
  "t_slalom": null,
  "t_slalomr": null,
  "t_speedbump12km": null,
  "t_speedbump1km": null,
  "t_testBowl": null,
  "t_testBowlr": null,
  "t_tokyoBridge": null,
  "t_tokyoDrag": null,
  "t_tokyoGforce": null,
  "t_tokyoLoop": null,
  "t_tokyoOffRamp": null,
  "t_tokyoOverpass": null,
  "t_tCircuit": null,
  "t_tCircuitr": null,
  "t_tRoad": null,
  "t_waterDrag": null,
  "t_laguna": null,
  "t_lumberTwisty": null,
  "t_desertHill": null,
  "t_desertSmall": null,
  "t_desertBig": null,
  "t_desertRallyDirt": null,
  "t_dockAltCirc": null,
  "t_dockCirc": null,
  "t_dockCity": null,
  "t_dockGforceDrag": null,
  "t_dockKart": null,
  "t_dockUdrag": null,
  "t_lumberForest": null,
  "t_lumberRiver": null,
  "t_drag62": null,
  "t_drag124": null,
  "t_dealsGap1": null,
  "t_dealsGap2": null,
  "t_dealsGap3": null,
  "t_dealsGap4": null,
  "t_dealsGap5": null,
  "t_dealsGap": null,
  "t_dealsGapBack": null,
  "t_nwCircuit": null,
  "t_nwDrag": null,
  "t_nwGforce": null,
  "t_nwLoop": null,
  "t_nwCathedral": null,
  "t_nwTour": null,
  "t_nwSlalom": null,
  "t_miCircuit": null,
  "t_miStreets1": null,
  "t_miStreets2": null,
  "t_miUdrag": null,
  "t_miCause": null,
  "t_miCauseDrag": null,
  "t_miBridge": null,
  "t_miFigure": null,
  "t_miSlalom": null,
  "t_miGforce": null,
  "t_miBeachDrag": null
}