var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"BaseTooltip_Layout"},[_c('div',{staticClass:"BaseTooltip_Box",class:{
      BaseTooltip_Bottom: _vm.tooltip.bottom,
      BaseTooltip_Active: _vm.active,
      BaseTooltip_RealActive: _vm.realActive,
      BaseTooltip_PreActive: _vm.preActive,
      BaseTooltip_AnimIn: _vm.animIntro,
      BaseTooltip_AnimOut: _vm.animOut,
      [_vm.tooltip.contentClass]: true
    },style:(`
      --positionx: ${_vm.tooltip.positionX}px;
      --positiony: ${_vm.tooltip.positionY}px;
    `)},[(_vm.tooltip.body)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.tooltip.body)}}):[_c('span',{staticClass:"BaseTooltip_Message"},[_vm._v(_vm._s(_vm.tooltip.mensagem))])]],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }