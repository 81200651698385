var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.lazy || _vm.active)?_c('div',{staticClass:"BaseDialog_Layout",class:{
    BaseDialog_Active: _vm.active,
    BaseDialog_RealActive: _vm.realActive,
    BaseDialog_PreActive: _vm.preActive,
    BaseDialog_AnimIn: _vm.animIntro,
    BaseDialog_AnimOut: _vm.animOut,
    BaseDialog_Fixed: _vm.fixed,
  },style:(`--dialog-index: ${_vm.zindex}`)},[(!_vm.fixed)?_c('div',{staticClass:"BaseDialog_Back",on:{"click":function($event){return _vm.$emit('close')}}}):_vm._e(),_c('div',{staticClass:"BaseDialog_Body",class:{ BaseDialog_Body_Static: _vm.isStatic, BaseDialog_Body_Fixed: _vm.fixed }},[_c('div',{staticClass:"BaseDialog_Box Main_DarkScroll",class:{
        BaseDialog_Opaque: !_vm.transparent,
        BaseDialog_Transparent: _vm.transparent,
        BaseDialog_ForceScroll: _vm.forceScroll,
        BaseDialog_ForceNoScroll: _vm.disableScroll,
        BaseDialog_Grow: _vm.grow,
        BaseDialog_Contents: _vm.displayContents
      },style:({
        maxWidth: _vm.maxWidth,
        minWidth: _vm.minWidth,
      })},[_vm._t("default")],2)])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }